import request from "../utils/request";


export function getRealTimeStatistics() {
  return request(
    {
      url: '/statistics/real-time',
      method: 'get'
    }
  )
}

export function getStatistics(key = 'day')
{
  return request({
                   url   : `/statistics/${key}`,
                   method: "get",
                 });
}

export function fetchEditors(key)
{
  return request(
    {
      url   : `/statistics/editors/${key}`,
      method: "get"
    }
  )
}

export function fetchLastNews()
{
  return request(
    {
      url   : "/news/last-news/5",
      method: "get"
    }
  )
}


export function fetchMostVisitedPages(key){
  return request(
    {
      url: `/fetchMostVisitedPages/${key}`,
      method: "get"
    }
  )
}
