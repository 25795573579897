<template>
  <div class="container-fluid">
    <div class="row mt-0 g-4">
        <CardsGroup/>

    <!--  news  -->

      <div class="col-lg-6">
        <el-card class="h-100">
          <div class="table-filters mb-3">
            <h5 class="table-header pt-2">آخر الاخبار</h5>
          </div>
          <el-table id="top-table" :data="latestNews" class="mb-4 latest-news" stripe style="width: 100%" max-height="850"
                    v-loading="loading">
            <el-table-column type="index" class-name="px-2" label="#" width="40"/>

            <el-table-column prop="image" width="70" label="صورة">
              <template #default="scope">
                <div class="symbol d-block">
                  <img class="news-img" :src="APP_URL + '95x65/' + scope.row.image" alt="">
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="title" width="340" class-name="text-end" label="عنوان الخبر">
              <template #default="scope">
                <a :href="scope.row.url" :title="scope.row.title" class="news-link w-100" target="_blank">
                  {{ scope.row.title }}
                </a>
              </template>
            </el-table-column>

            <el-table-column prop="category" width="200" class-name=" text-center" label="التصنيف">
              <template #default="scope">
                {{ scope.row.category.name }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>

      <div class="col-lg-6">
        <el-card class="h-100">
          <MostActiveEditors/>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, onMounted, defineAsyncComponent, computed} from "vue";
import {fetchLastNews} from "@/services/dashboard";

const CardsGroup       = defineAsyncComponent(() => import("./components/CardsGroup"))
const MostActiveEditors = defineAsyncComponent(() => import("./components/MostActiveEditors"))

const latestNews = ref([])
const loading    = ref(true)
const APP_URL    = computed(() => process.env.VUE_APP_IMAGE_REMOTE_URL)

onMounted(async () => {
  try
  {
    const result     = await fetchLastNews()
    latestNews.value = await result.data;
    loading.value    = false;
  } catch (e)
  {
    console.log(e)
  }
})

</script>

<style>
.latest-news .cell {
  padding: 0;
}
.el-table .cell{
  word-break: keep-all;
}

.news-img {
  aspect-ratio: 1 / 1;
}
</style>
